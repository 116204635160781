<template lang="pug">
  div.transport-wrapper
    slot(name="activator" :toggle="toggle")
      v-btn(small color='primary'
        @click="toggle"
        :disabled="disabled"
        outlined
      ) {{ activatorText }}
    app-dialog(v-model="showing" scrollable width=320 :title="title")
      template(#dialog-body)
        v-card
          v-card-text
            v-text-field(
              v-model="formData.email"
              placeholder="Email"
              :error="formErrors.hasError('email')"
              :error-messages="formErrors.fields.email"
            )
            v-text-field(
              v-model="formData.name"
              placeholder="Name"
              :error="formErrors.hasError('name')"
              :error-messages="formErrors.fields.name"
            )
            field-date-picker(
              v-model="formData.exam_date"
              :error="formErrors.hasError('exam_date')"
              :error-messages="formErrors.fields.exam_date"
              :only-future-date="false"
              label="Exam date"
            )

      template(#dialog-footer)
        v-spacer
        app-button(color="#f4f4f4" hide-shadow @click.native="updateShowing(false)").mr-2 Close
        app-button(color="primary" @click.native="submit") Send
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import showingMixin from '@/mixins/showing.mixin'
import CandidatesActionsService from '@/app/admin/modules/candidates/core/candidates-actions-service'
import { DATE_DEFAULT_REGXP } from '@/util/const'

export default {
  name: 'candidateSendCertificate',

  mixins: [errorsMixin, showingMixin],

  props: {
    disabled: Boolean,
    title: {
      type: String,
      default: 'Certificate email'
    },
    activatorText: {
      type: String,
      default: 'Send certificate email'
    },
    item: Object
  },

  data: () => ({
    formData: {
      email: '',
      name: '',
      exam_date: ''
    },
    formErrors: new FormErrors(),
  }),

  methods: {
    submit() {
      if (!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    validate(validator) {
      validator.resetFields()
      if (!this.formData.name) validator.invalidField('name', 'field required')
      if (!this.formData.email) validator.invalidField('email', 'field required')
      if (!this.formData.exam_date) validator.invalidField('exam_date', 'field required')
      if (!(DATE_DEFAULT_REGXP.test(this.formData.exam_date)))
        validator.invalidField(
          'exam_date',
          'Date should be in DD-MM-YYYY format'
        )
      return validator.isValid()
    },

    async send() {
      try {
        let res = await new CandidatesActionsService().sendCertificateEmail(this.formData)
        this.$notify({text: 'CandidatesActionsServiceUpdated', type: 'success'})
        this.updateShowing(false)
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            email: 'email',
            name: 'name',
            exam_date: 'exam_date'
          }
        });
      }
    },

    reset() {
      this.formData = {
        email: '',
        name: '',
        exam_date: ''
      }
    },
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) {
        this.reset()
        return
      }

      if (this.item.candidateEmail) this.formData.email = this.item.candidateEmail
    }
  },

  components: {
    transportIcon: () => import('@/components/global/TransportIcon.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue'),
    fieldDatePicker: () => import('@/components/global/FieldDatepicker.vue'),
  }
}
</script>

<style lang="scss">
.transport-type-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 4px;
  height: 41px;
  border: 1px solid $border-color;
  border-radius: 4px;

  .v-popover {
    width: 100%;
  }

  input {
    color: $input-color;
  }
}


.transport-wrapper {
  min-width: 24px;
  min-height: 10px;
}
</style>
